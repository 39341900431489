export enum Peppers {
  Cultivar = 'Cultivar',
  Date = 'Date',
  Heat = 'Heat (SHU)',
  HeatFrom = 'Heat MIN',
  HeatTo = 'Heat MAX',
  ID = 'ID',
  Origin = 'Origin',
  ParentPlant = 'Parent Plant',
  ParentSeed = 'Parent Seed',
  PodPhoto = 'Add Photo',
  SHU = 'SHU',
  Source = 'Source',
  Species = 'Species',
}
